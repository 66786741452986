export default defineNuxtRouteMiddleware(async (to) => {
  const form = useFormStore()

  if (
    (!form.form || form.form.metadata?.status !== 'active') &&
    to.fullPath !== '/'
  ) {
    return navigateTo('/')
  }
})
